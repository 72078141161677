// src/components/Footer.js
import React from 'react';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <footer className="bg-primary text-white text-center py-3">
      <Container>
        <div>
          <a href="https://www.facebook.com/mynorthwoodstech" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a href="mailto:tom@mynorthwoodstech.com" className="text-white mx-2">
            <FontAwesomeIcon icon={faEnvelope} size="2x" />
          </a>
        </div>
        <p className="mt-3">© 2024 Northwoods Tech. All rights reserved.</p>
      </Container>
    </footer>
  );
}

export default Footer;
