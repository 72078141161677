// src/components/AboutUs.js
import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import '../index.css'; // Ensure your CSS is imported

const AboutUs = () => {
  return (
    <Container>
      <Card className="my-3">
        <Card.Body>
          <h2 className="about-title">Welcome to Northwoods Tech! 🌲💻</h2>
          <p className="about-text">
            My name is Tom Sciano. I have a degree in Computer Science from Colorado State University and have been working in website and software development for the past 14 years.
          </p>
          <h3 className="about-subtitle">My Experience</h3>
          <p className="about-text">
            I have been fixing computers and solving various tech problems since the early 90s. Throughout high school and college, I ran my own computer repair business in Colorado, helping people and businesses with their tech needs.
          </p>
          <p className="about-text">
            Since 2004, I have been designing websites and providing innovative tech solutions to clients, further expanding my expertise in the tech industry.
          </p>
          <h3 className="about-subtitle">What I Offer</h3>
          <p className="about-text">
            At Northwoods Tech, I specialize in solving your tech challenges with professionalism and expertise. Whether you need fast in-home repairs, comprehensive business support, or remote management services, I am here to help.
          </p>
          <h3 className="about-subtitle">Contact Me</h3>
          <p className="about-text">
            Contact me today to learn more about how I can help you with your tech challenges.
          </p>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AboutUs;
