// src/components/Services.js
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import services from '../data/services.json';

const Services = () => {
  return (
    <Container className="container">
      <h2 className="title">Our Services</h2>
      <Row>
        {services.map((service, index) => (
          <Col key={index} sm={12} md={6} lg={4} className="mb-4">
            <Card className="service-card">
              <Card.Body>
                <Card.Title className="title">{service.name}</Card.Title>
                <Card.Text>
                  {service.description.split('\n').map((line, idx) => (
                    <React.Fragment key={idx}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </Card.Text>
                <Card.Text className="price">Price: {service.price}</Card.Text>
                <Card.Text className="details">Details: {service.details}</Card.Text>
                <Card.Text>
                  <strong>Benefits:</strong>
                  <ul>
                    {service.benefits.map((benefit, idx) => (
                      <li key={idx}>{benefit}</li>
                    ))}
                  </ul>
                </Card.Text>
                {service.note && (
                  <Card.Text className="note">
                    <strong>Note:</strong> {service.note}
                  </Card.Text>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Services;
