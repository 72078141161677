// src/components/Information.js
import React from "react";
import Card from "react-bootstrap/Card";

function Information() {
  return (
    <Card className="my-3">
      <Card.Body>
        <Card.Title>Welcome to Northwoods Tech! 🌲💻</Card.Title>
        <Card.Text>
          <p>
            At Northwoods Tech, we're dedicated to providing reliable and innovative tech solutions for individuals and businesses alike. Our team of experts is here to help you with all your tech needs, from computer repairs to network setup and more.
          </p>
          <p>
            We pride ourselves on delivering exceptional service and support, ensuring that your devices run smoothly and efficiently.
          </p>

          <p>
            At Northwoods Tech, we specialize in solving your tech challenges with professionalism and expertise. Whether you need fast in-home repairs, comprehensive business support, or remote management services, we are here to help.
          </p>
          <p>
            We are dedicated to delivering exceptional service, ensuring your devices run smoothly so you can focus on what matters most.
          </p>
          <p>
            With over 10 years of experience in the tech industry, we have the knowledge and skills to tackle any tech challenge you may face.</p>
          <p>
            We offer a wide range of services, including:
          </p>
          <ul>
            <li>Computer Repairs</li>
            <li>Virus Removal</li>
            <li>Network Setup</li>
            <li>Ring Camera Installs</li>
            <li>Starlink Setups</li>
            <li>Internet Testing</li>
            <li>Data Recovery</li>
            <li>Free Consultation!</li>
          </ul>
          <p>
            If we can't fix it, we'll point you in the best direction to get the help you need.
          </p>
          <p>
            Contact us today to learn more about how we can help you with your tech challenges.
          </p>
          <p>Your reliable partner for computer repair and tech consulting.</p>
          <p>Northwoods Tech 🌲💻</p>
        </Card.Text>
      </Card.Body>
    </Card>

  );
}

export default Information;
