// src/components/Contact.js
import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const Contact = () => {
  return (
    <Card className="my-3 mx-auto" style={{ maxWidth: '400px' }}>
      <Card.Body>
        <Card.Title>Contact Us</Card.Title>
        <Card.Text>Email: tom@mynorthwoodstech.com</Card.Text>
        <Card.Text>Phone: (720) 773-0548</Card.Text>
        <Card.Text>
          Message us on{' '}
          <a
            href="https://www.facebook.com/mynorthwoodstech"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>{' '}
          or Instagram as well!
        </Card.Text>
        <Button variant="primary" href="mailto:tom@mynorthwoodstech.com">
          Email Us
        </Button>
      </Card.Body>
    </Card>
  );
};

export default Contact;
