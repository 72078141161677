// src/components/Home.js
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Title from './Title';
import FacebookReviews from './FacebookReviews';
import GoogleReviews from './GoogleReviews';
import Information from './Information';
import ServiceArea from './ServiceArea';
import '../index.css';

function Home() {
  return (
    <Container>
      {/* <Title /> */}
      <Row>
        <Information />
        {/* <Col>
          <FacebookReviews />
        </Col> */}
      </Row>
      <Row>
        <Col>
          <GoogleReviews />
        </Col>
      </Row>
      <ServiceArea />
    </Container>
  );
}

export default Home;
