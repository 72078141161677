// src/components/GoogleReviews.js
import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../index.css'; // Ensure your CSS is imported

function GoogleReviews() {
  const reviews = [
    {
      text:
        `Tom was more than helpful in cleaning & tuning up our home computer for us. 
      
      He was timely, fairly priced & did great work. 
      
      He explained everything he did & was incredibly patient with all of my questions. 
      
      I'd recommend him to anyone in need of home OR business computer help!`,
      name: "Kelly A."
    },
    {
      text:
        `Quick turnaround (same day)

      Friendly service (Picked up and dropped off my computer)

      Knowledgeable and helpful! (installed ad block and child lock)

      Reasonably priced (almost half what other guys charge)

      No nonsense tactics - you know you are doing business with a company that cares and won't try to take advantage of you!

      Thanks Tom!`,
      name: "A. H."
    },
    {
      text: `My computer was slower than usual and had lots of popups and ad.
      
      Tom helped me out by cleaning my computer to where it was better than when I first bought it. 

      Great service, and I would recommend them to anyone with a computer issue`,
      name: "Pat G."
    }
  ];

  return (
    <Card className="my-3">
      <Card.Body>
        <Card.Title className="title">Google Reviews</Card.Title>
        <Row>
          {reviews.map((review, index) => (
            <Col key={index} md={4}>
              <Card className="mb-3 review-card">
                <Card.Body>
                  <Card.Text className="review-text">
                    {review.text.split('\n').map((line, idx) => (
                      <React.Fragment key={idx}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </Card.Text>
                  <Card.Footer className="review-footer">
                    <small className="text-muted">{review.name}</small>
                  </Card.Footer>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
}

export default GoogleReviews;
