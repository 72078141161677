// src/components/Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../index.css'; // Ensure your CSS is imported

function Header() {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleLinkClick = () => {
    if (expanded) {
      setExpanded(false);
    }
  };

  return (
    <Navbar expanded={expanded} onToggle={handleToggle} expand="lg" className="navbar-custom">
      <Navbar.Brand href="/">Northwoods Tech 🌲💻</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link as={Link} to="/" onClick={handleLinkClick}>Home</Nav.Link>
          <Nav.Link as={Link} to="/services" onClick={handleLinkClick}>Services</Nav.Link>
          <Nav.Link as={Link} to="/about-us" onClick={handleLinkClick}>About Us</Nav.Link>
          <Nav.Link as={Link} to="/contact" onClick={handleLinkClick}>Contact Us</Nav.Link>
          <Nav.Link href="https://www.facebook.com/mynorthwoodstech" target="_blank" onClick={handleLinkClick}>
            <FontAwesomeIcon icon={faFacebook} className="fa-icon" /> Facebook
          </Nav.Link>
          <Nav.Link href="https://www.instagram.com" target="_blank" onClick={handleLinkClick}>
            <FontAwesomeIcon icon={faInstagram} className="fa-icon" /> Instagram
          </Nav.Link>
          <Nav.Link href="mailto:tom@mynorthwoodstech.com" onClick={handleLinkClick}>
            <FontAwesomeIcon icon={faEnvelope} className="fa-icon" /> Email
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
