// src/components/NotFound.js
import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';

const NotFound = () => {
    return (
        <Container>
            <Card className="my-3">
                <Card.Body>
                    <Card.Title>404 - Page Not Found</Card.Title>
                    <Card.Text>Sorry, the page you are looking for does not exist.</Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default NotFound;
