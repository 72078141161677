// src/components/FacebookReviews.js
import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function FacebookReviews() {
  return (
    <Card className="my-3">
      <Card.Body>
        <Card.Title>Facebook Posts and Reviews</Card.Title>
        <Row>
          <Col>
            <Card className="mb-3">
              <Card.Body>Review 1</Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mb-3">
              <Card.Body>Review 2</Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="mb-3">
              <Card.Body>Review 3</Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default FacebookReviews;
