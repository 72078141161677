// src/components/ServiceArea.js
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import '../index.css'; // Ensure your CSS is imported

const ServiceArea = () => {
    return (
        <Row className="my-4">
            <Col>
                <h3 className="title">Service Area</h3>
                <Card className="mb-4 service-area-card">
                    <Card.Body>
                        <Card.Title className="flat-rates-title">Flat Rates</Card.Title>
                        <Card.Text className="flat-rates-text">
                            Our travel rates are based on the distance from <br /> Downtown Minocqua, WI:
                        </Card.Text>
                        <ul className="flat-rates-list">
                            <li>10 Miles: $10</li>
                            <li>20 Miles: $20</li>
                            <li>30 Miles: $30</li>
                            <li>40 Miles: $40</li>
                        </ul>
                    </Card.Body>
                </Card>
                <img src="/ServiceArea.JPG" alt="Service Area" className="img-fluid" />
            </Col>
        </Row>
    );
};

export default ServiceArea;
